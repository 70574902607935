@import '_settings';
@import '../../node_modules/foundation-sites/scss/foundation';

// Foundation Components
// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
//@include foundation-button-group;
//@include foundation-close-button;
@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
//@include foundation-table;
// Basic components
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-dropdown;
//@include foundation-pagination;
//@include foundation-tooltip;

// Containers
//@include foundation-accordion;
//@include foundation-media-object;
//@include foundation-orbit;
//@include foundation-responsive-embed;
//@include foundation-tabs;
//@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
//@include foundation-accordion-menu;
//@include foundation-drilldown-menu;
//@include foundation-dropdown-menu;

// Layout components
//@include foundation-off-canvas;
//@include foundation-reveal;
//@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
//@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;


@import url('https://fonts.googleapis.com/css?family=Montserrat:200|Open+Sans&display=swap');


$green: #87A2D1;
$blue: #87A2D1;
$background: #F9F9F9;
$additionalBackground: #222244;

@import 'custom/app';
@import 'custom/header';
@import 'custom/footer';
@import 'custom/photo';
@import 'custom/calendar';
@import 'custom/map';
@import 'custom/tags';
@import 'custom/video';
@import 'custom/loading';
@import 'custom/error';
@import 'custom/mobile-nav';
@import 'custom/about';
@import 'custom/thumbnail';
@import 'custom/password';

@import 'vendor/leaflet';
