@media only screen and (min-width: 40em) {
    .thumbnail-container {
        padding-left:44px;
    }
}

@media only screen and (min-width: 667px) {
    .thumbnail-container {
        padding-left:24px;
    }
}

.thumbnail {
    font-weight: 300 !important;
    text-align: center;
    margin:8px;
    width:110px;
    height:110px;
    
    background-position:center; 
    background-repeat:no-repeat; 
    background-size: cover; 
    overflow:hidden;

    a {
        text-align:center;
        color:#fff;
        font-size:2rem;
        font-weight: 300 !important;
        padding-top:35px;
        display: block;
        
        width: 100%;
        height:100%;
    }

    a:hover {
        background-color:rgba(0,0,0,.25);
        span {
            color:#fff;
        }
    }
    
}

.thumbnail.active > a {
    background-color:transparent;
    border:4px solid $white;
}


.thumbnail.empty, .thumbnail.expire {
    color:#ccc;
    background-color: #EAEAEA;
    font-size:2rem;
    font-weight: 300 !important;
    padding-top:35px;
}

.thumbnail.expire {
    background-image: -webkit-gradient(linear, 0 100%, 100% 0,
                            color-stop(.25, rgba(255, 255, 255, .2)), color-stop(.25, transparent),
                            color-stop(.5, transparent), color-stop(.5, rgba(255, 255, 255, .2)),
                            color-stop(.75, rgba(255, 255, 255, .2)), color-stop(.75, transparent),
                             to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%,
                        transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%,
                        transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%,
                        transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%,
                        transparent 75%, transparent);
    background-image: -ms-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%,
                        transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%,
                        transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%,
                        transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%,
                        transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%,
                        transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%,
                        transparent 75%, transparent);
}

// iphone:
@media only screen and (max-width: 667px) {
    .thumbnail {
        width:102px;
        height:102px;       
    }
}