
.mapcontainer {

	position:relative;
	overflow: hidden;

	#map {
		width:100%;
		height:100%;
	}

	.ol-control.geolocate-control {
		position: absolute;
		top: 83px;
		left: 10px;
		width:34px;
		height:34px;
		z-index: 1000;
		cursor: pointer;
		background-color: #fff;
		background-image: url('/images/map/geolocate-button.png');
		background-size:18px;
		background-repeat:no-repeat;
		background-position:center;
		border:2px solid rgba(0,0,0, .2);
		border-radius: 2px;
	}

	.ol-control.geolocate-control:hover {
		background-color:#f4f4f4;
	}

	.marker-cluster-small {
		background-color: rgba(181, 226, 140, 0.6);
		div {
			background-color: rgba(110, 204, 57, 0.6);
		}
	}

	.marker-cluster-medium {
	background-color: rgba(241, 211, 87, 0.6);
		div {
			background-color: rgba(240, 194, 12, 0.6);
		}
	}

	.marker-cluster-large {
		background-color: rgba(253, 156, 115, 0.6);
		div {
			background-color: rgba(241, 128, 23, 0.6);
		}
	}

	.marker-cluster {
		background-clip: padding-box;
		border-radius: 20px;
	}

	.marker-cluster div {
		width: 30px;
		height: 30px;
		margin-left: 5px;
		margin-top: 5px;

		text-align: center;
		border-radius: 15px;

	}
	.marker-cluster span {
		line-height: 30px;
	}

	.leaflet-popup-content {
		text-align: center;
		img {
			min-width:100px !important;
			display: block;
			margin:0 auto 2px auto;
		}
		.title {
			max-width: 100px;
			margin:0 auto;
		}
		.date {
			color:$medium-gray;
			font-style: italic;
		}
	}
}

