.top-bar {
	padding: .5rem .5rem 0 .5rem;
	a {
		color:$white;
		font-size:.8em;
		font-weight: 300 !important;
	}
}
.top-bar .menu-text {

	a {
		padding:0 1rem !important;
		font-size: 2em;
		margin: 0 !important;
		letter-spacing: -.05em;
		line-height: inherit;
		font-weight: 300 !important;
		color:$green;

		span {
			font-size: .5em !important;
			letter-spacing: 0;
			color:#919191;
		}

		span.testing {
			color:#bb2222;
		}
	}
}