.date-description {
	h2 {
		text-align: center;
	}
}

.photo {

	background-color:#fff;
	padding-bottom: 25px;
	margin-bottom: 25px;
	border-bottom:1px solid #EAEAEA;

	.menu.tags a {
		padding-left:0;
	}

	.menu a.edit-link {
		padding:4px 0;
	}

	.meta {
		font-size: 0.9rem;
		margin:.5rem 1rem .5rem 0;
	}

	img {
		width: auto !important;
		height: auto !important;
		display: block;
		margin: 0 auto;
	}

	img.widescreen {
		width: 100% !important;
	}

	a.photoMap {
		display: block;
		margin-top: 1em;
		width: auto !important;
		height: auto !important;
		img {
			border:1px solid #e1e1e1;
			padding:2px;
			margin:0;
		}
	}
	a.photoMap:hover img {
		border-color:$blue;
	}

	p.location {
		font-size: 70%;
		margin-top: 3px;
	}
}

.day-intro {
	h2 {
		text-align:center;
		color:#1779ba;
	}
}


@media only screen and (max-width: 40em) {
	.next-previous {
		p {
			text-align:center !important;
		}
	}
	
	.photo {
		img {
			width: 100% !important;
			height: 100% !important;
		}
	}
}

.photo-history {

	background-color:$black;

}

a .label {
	cursor: pointer;
}

a:hover .label {
	background-color:#1779ba;
}