.password {
  .content {
    border-radius: 15px;
    padding: 50;
    margin-top: 25px;

    .password {
      width: 90%;
      margin: auto;
      display: block;
    }

    .button {
      margin: 10px auto;
      display: inherit;
    }
  }
}
