.about {
    h2 {
        text-align: center;
        span {
            display: block;
            margin:0 auto;
            padding-top:12%;
            border-radius: 50%;
            width: 100px;
            height: 100px; 
            background-color:#e1e1e1;
        }
    }

    $gray: #6D6E71;
    $light-gray: #e1e1e1;
    $dark-blue: #264c8c;

    #calendar {
        max-width: 150px;
        display: block;
        fill: $gray;
        margin:0 auto;

        .st2{font-size:80px; fill:$blue;}
        .st3{font-size:30px;}
    }

    a:hover #compass .st1 {
        fill:$blue;
    }
    #compass {
        max-width: 250px;
        margin: 1em auto 0 auto;
        display: block;

        .st0{fill:#FFFFFF;stroke:$light-gray;stroke-width:15;stroke-miterlimit:10;}
        .st1{fill:$gray;}
        .st3{font-size:28.9703px; fill:white}
        .st4{font-size:12px; fill:white}
        .st5 {fill:$blue}
    }

    #tags {
        max-width: 200px;
        display: block;
        margin: 15% auto 0 auto;
    
        .st0{fill:$gray;}
        .st1{fill:none;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;}
        .st2{fill:#FFFFFF;}
        .st4{font-size:64.0469px;}
        .st5{font-size:12px;}
        .star {fill:$blue;}
    }
    a:hover #tags .st4 {
        fill: $blue;
    }

    #photo {
        display: block;
        margin:0 auto;
        max-width: 175px;
        .st0{fill:none;stroke:$gray;stroke-width:5;stroke-linejoin:round;stroke-miterlimit:10;}
        .st1{fill:$blue;stroke:$gray;stroke-width:5;stroke-miterlimit:10;}
        .st2{fill:$blue; stroke:$gray;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        .st3{fill:$gray;}
        .st5{font-size:65.1589px; fill:#fff;}
        .st6{font-size:25.2391px; font-weight: bold; fill:$gray}
    }

    #graph {
        .back {fill:$light-gray; cursor: pointer;}
        .front {fill:$blue; cursor: pointer;}
        .active .front {fill:$dark-blue}
        #Labels text {font-size:12px;}
        #Labels text.active {fill:$dark-blue; font-weight: bold;}
        #Lines line {fill:none;stroke:$gray;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
    }

    #camera {
        p {
            margin: 0;
            text-align: center;
        }
        svg{
            display: block;
            margin: 20% auto 0 auto;
            max-width: 150px;
            fill: $gray;
        }
        .st0{fill:#FFFFFF;}
        .st2{font-size:77.3015px;}
    }

    #videos {
        display: block;
        max-width: 175px;
        margin:0 auto;
        .st0{fill:$gray;}
        .st1{fill:#FFFFFF;}
        .st2{fill: $blue;}
        .st4{font-size:85.2078px; fill: $blue}
        .st5{font-size:25.3087px;  fill: $gray}
    }
    a:hover #videos .st1 {
        fill:#e1e1e1;
    }
}

@media only screen and (max-width: 40em) {
    #tags {margin-top: 0 !important;}
    #camera svg {margin-top:0 !important;}
}