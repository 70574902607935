.calendar {

	.title>div {
		text-align: center;
		width:110px;
		margin:0 .5em;
	}

}


.calendar-months {

	h4 {padding-left:15px;}
	li {
		color:#566a8d;
		border-left:5px solid #222244;
		padding-left:10px
	}

	a {
		color:#c6d7f5;
		display: block;
	}

	a:hover{
		color:#fff;
	}

	li.active {
		border-left-color:#fff;
		a {
			color:#fff;
		}
	}
}


@media only screen and (max-width: 944px) {

	.calendar {
		.thumbnail {
			width: 12% !important;
			font-size:1.25em;
			margin:4px !important;
			height:60px !important;
		}

		.thumbnail.empty, .thumbnail.expire, .thumbnail a {
			padding-top:5px !important;
			
		}

		.title {display: none;}
	}
}