
footer {

	background-color:$black;

	p {
		font-size: .7em;
		color:$light-gray;
		margin: 0 0 1em 0;
		padding: 0;
	}
	
	button {
		color:$light-gray;
		cursor: pointer;
	}
}