body {
	* {
		font-family: 'Open Sans', 'sans-serif' !important;
	}

	h1, h1 span, h2, h3, h4, h5, h6, .menu-text a {
		font-family: 'Montserrat', sans-serif !important;
	}
}

.major-section {
	padding-top:25px;
	padding-bottom:25px;
}
.minor-section {
	padding-top:10px;
	padding-bottom:10px;
}

.content {
	background-color: $background;
}

.additional-content {
	background-color: $additionalBackground;

	h2, h3, h4, h5, p, li {
		color:#fff;
	}
	a {
		color:$green;
	}
}

h1 {
	color:$medium-gray;
	font-weight:300 !important;
	font-size:1.7875rem;
	text-align:center;
	line-height: 2em;
}

h2 {
	color:$medium-gray;
	font-size: 1.7rem;
}

.date-description h2 {
	font-size: 1.4em;
}

h3 {
	color:$medium-gray;
	font-size: 1.4em;
}


.row {
	width:100%;
	clear: both;
}